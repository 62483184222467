import React from 'react'


const HeaderImg = () => (
    <div>
        <div className="headerImg-bg-pages">

        </div>


    </div>
)

export default HeaderImg
