import React from "react"
import Layout from "../components/Layout/Layout"
import HeaderImg from "../components/Components/HeaderImg";
import ContactForm from "../components/Components/ContactForm";
import GoogleMap from "../components/Components/GoogleMap";

export default function Kontakt() {
    return (
        <Layout>
            <HeaderImg />
            <div className="container pb-5">
                <h1>Kontakt</h1>

                <h2>Provozovna:</h2>
                <GoogleMap/>

                {/*<p>Media Luna s.r.o.<br />
                    Smilova 307<br />
                    530 02 Pardubice
                    </p>*/}
            </div>
            {/*   <ContactForm />*/}


        </Layout>
    );
}